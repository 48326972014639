/* eslint-disable prettier/prettier */
import accounting from 'accounting-js';

accounting.settings = {
  currency: {
    symbol: 'R$',
    format: '%s %v',
    decimal: ',',
    thousand: '.',
    precision: 2
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ','
  }
};

export default accounting;

export function numberRound(value, precision = 2) {
  return Number(accounting.toFixed(value, precision));
}

export function formatMoney(value, precision = 2) {
  return accounting.formatMoney(value, {
    precision,
    symbol: 'R$',
    decimal: ',',
    thousand: '.'
  });
}

// currency to double javascript
export function currencyToDouble(value) {
  const newValue = String(value).replace(/[^0-9.-]+/g, '');
  return Number(newValue);
}

// accouting parse currency to double javascript
export function parseCurrencyToDouble(value) {
  const ret = accounting.unformat(value);
  console.log('🚀 ~ file: accouting.js:40 ~ parseCurrencyToDouble ~ ret:', ret);
  return accounting.toFixed(ret, 2);
}

export function moeda(a, e, r, t) {
  let tamanho2, ajd2, j;
  let n = '',
    h = (j = 0),
    u = (tamanho2 = 0),
    l = (ajd2 = ''),
    o = window.Event ? t.which : t.keyCode;
  if (13 == o || 8 == o) return !0;
  if (((n = String.fromCharCode(o)), -1 == '0123456789'.indexOf(n))) return !1;
  for (
    u = a.value.length, h = 0;
    h < u && ('0' == a.value.charAt(h) || a.value.charAt(h) == r);
    h++
  );
  for (l = ''; h < u; h++)
    -1 != '0123456789'.indexOf(a.value.charAt(h)) && (l += a.value.charAt(h));
  if (
    ((l += n),
      0 == (u = l.length) && (a.value = ''),
      1 == u && (a.value = '0' + r + '0' + l),
      2 == u && (a.value = '0' + r + l),
      u > 2)
  ) {
    for (ajd2 = '', j = 0, h = u - 3; h >= 0; h--)
      3 == j && ((ajd2 += e), (j = 0)), (ajd2 += l.charAt(h)), j++;
    for (a.value = '', tamanho2 = ajd2.length, h = tamanho2 - 1; h >= 0; h--)
      a.value += ajd2.charAt(h);
    a.value += r + l.substr(u - 2, u);
  }
  return !1;
}

export function Utf8ArrayToStr(array) {
  let out, i, len, c;
  let char2, char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }

  return out;
}


export function parseDouble(value) {
  const ret = accounting.unformat(value);
  console.log('🚀 ~ file: accouting.js:40 ~ parseCurrencyToDouble ~ ret:', ret);
  return accounting.toFixed(ret, 2);
}
