import { apiSlice } from '../api/apiSlice';
import { Info } from '../users/models';
import { Produto, LastMovim, ProdutoCadastro, ProdutoLote } from './models';
import queryString from 'query-string';

const endpointUrlSGI = '/produtos/sgi/find';
const endpointUrl = '/produtos';

function findProdutosSGI({
  tipoReq,
  searchFor,
  pLocal
}: {
  tipoReq: string;
  searchFor: string;
  pLocal: string;
}) {
  const params = {
    parametrosPesquisa: {
      tipoReq: tipoReq,
      searchFor,
      pesquisarNosCampos:
        'p.codigo, p.descricao, p.referencia, p.descricaoSite, p.CodigoFabricante, p.Descricao_Reduzz',
      tipoPesquisa: 0,
      pFilial: "('00')",
      pLocal,
      apenasAtivos: true,
      retirarEspacosPontuacoes: true
    }
  };

  return { url: endpointUrlSGI, method: 'POST', body: params };
}

type FindFichaSGI = Record<string, any>;

type ResultFindFichaSGI = {
  movimentacao: LastMovim[];
  qtde: number;
};

export interface ResultProduto {
  info: Info;
  produtos: ProdutoCadastro[];
}

function findFichaSGI(args: FindFichaSGI) {
  const params = {
    parametrosPesquisa: {
      tipoMov: "('040')",
      pQtdeReg: 20,
      ...args
    }
  };

  return { url: '/produtos/sgi/ficha', method: 'POST', body: params };
}

// busca saldo do produto
type FindProdutoSaldoSGI = {
  tipoReq: string;
  codigoSgi: string;
  pLocal: string;
};

function findProdutoSaldoSGI(args: FindProdutoSaldoSGI) {
  const params = {
    parametrosPesquisa: {
      searchFor: args.codigoSgi,
      pesquisarNosCampos: 'p.codigo',
      tipoPesquisa: 0,
      pFilial: "('00')",
      pLocal: ` ('${args.pLocal}')`,
      apenasAtivos: true,
      retirarEspacosPontuacoes: true
    }
  };

  return { url: '/produtos/sgi/find', method: 'POST', body: params };
}

interface ProdutoSearchFor {
  searchFor?: string;
  take?: number | string;
  skip?: number | string;
}

const produtoSearchFor = (params: ProdutoSearchFor) => {
  // add query params
  const query = queryString.stringify({ ...params });
  const url = endpointUrl + '?' + query;

  return { url: url, method: 'GET' };
};

const getProduto = (id: number | string) => {
  return { url: `${endpointUrl}/${id}`, method: 'GET' };
};

const addProduto = (produto: ProdutoCadastro) => {
  const prod = {
    ...produto,
    classeId: produto.classe?.id
  } as any;
  delete prod.classe;
  return { url: endpointUrl, method: 'POST', body: prod };
};

const updateProduto = (produto: Partial<ProdutoCadastro>) => {
  const prod = {
    ...produto,
    classeId: produto.classe?.id
  } as any;
  delete prod.classe;
  delete prod.images;
  return {
    url: `${endpointUrl}/${produto.id as number}`,
    method: 'PUT',
    body: prod
  };
};

const deleteProduto = (id: number | string) => {
  return { url: `${endpointUrl}/${id}`, method: 'DELETE' };
};

const addLote = (lote: ProdutoLote[]) => {
  return {
    url: `${endpointUrl}/lote`,
    method: 'POST',
    body: {
      produtos: lote
    }
  };
};

export const produtoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    findProdutosSGI: mutation<
      { produtos: Produto[]; qtde: number },
      { tipoReq: string; searchFor: string; pLocal: string }
    >({
      query: findProdutosSGI
    }),
    findFichaSGI: mutation<ResultFindFichaSGI, FindFichaSGI>({
      query: findFichaSGI
    }),
    findProdutoSaldoSGI: mutation<
      { produtos: Produto[]; qtde: number },
      FindProdutoSaldoSGI
    >({
      query: findProdutoSaldoSGI,
      invalidatesTags: ['Produtos']
    }),
    searchForProduto: mutation<ResultProduto, ProdutoSearchFor>({
      query: produtoSearchFor,
      invalidatesTags: ['Produtos']
    }),
    getProduto: query<ProdutoCadastro, number | string>({
      query: getProduto,
      providesTags: (result, error, id) => [{ type: 'Produtos', id }]
    }),
    addProduto: mutation<ProdutoCadastro, ProdutoCadastro>({
      query: addProduto,
      invalidatesTags: ['Produtos']
    }),
    updateProduto: mutation<ProdutoCadastro, Partial<ProdutoCadastro>>({
      query: updateProduto,
      invalidatesTags: ['Produtos']
    }),
    deleteProduto: mutation<void, number | string>({
      query: deleteProduto,
      invalidatesTags: ['Produtos']
    }),
    addProdutoLote: mutation<void, ProdutoLote[]>({
      query: addLote,
      invalidatesTags: ['Produtos']
    })
  })
});

export const {
  useFindProdutosSGIMutation,
  useFindFichaSGIMutation,
  useFindProdutoSaldoSGIMutation,
  useSearchForProdutoMutation,
  useAddProdutoMutation,
  useUpdateProdutoMutation,
  useDeleteProdutoMutation,
  useGetProdutoQuery,
  useAddProdutoLoteMutation
} = produtoApiSlice;
