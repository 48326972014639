import { toMySqlDate } from '../../utils/functions';
import { apiSlice } from '../api/apiSlice';
import { Pedido, ResultPedidos } from './models';

type FindParams = {
  take: number;
  skip: number;
  orderBy: string;
  sort: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any>;
};

const endpointUrl = '/pedidos';

function getPedidos() {
  return `${endpointUrl}`;
}

function findPedidos({ take, skip, orderBy, sort, body }: FindParams) {
  return {
    url: `${endpointUrl}/find/?take=${take}&skip=${skip}&orderBy=${orderBy}&sort=${sort}`,
    method: 'POST',
    body
  };
}

function getPedido({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function addLog({
  pedidoId,
  updated_at,
  obs
}: {
  pedidoId: number;
  updated_at: Date;
  obs: string;
}) {
  return {
    url: `${endpointUrl}/log`,
    method: 'POST',
    body: { pedidoId, updated_at, obs }
  };
}

function updLog({
  logId,
  pedidoId,
  updated_at,
  obs
}: {
  logId: number;
  pedidoId: number;
  updated_at: Date;
  obs: string;
}) {
  return {
    url: `${endpointUrl}/log/${logId}`,
    method: 'PUT',
    body: { pedidoId, updated_at, obs }
  };
}

function delLog({
  logId,
  pedidoId,
  updated_at
}: {
  logId: number;
  pedidoId: number;
  updated_at: Date;
}) {
  return {
    url: `${endpointUrl}/log/${logId}`,
    method: 'DELETE',
    body: { pedidoId, updated_at }
  };
}

interface IPedidoID {
  pedidoId: number;
}

export interface IBaixarRequisicao extends IPedidoID {
  requisicaoId: number;
  qtde: number;
  updated_at: Date;
}

function baixarRequisicao({
  pedidoId,
  requisicaoId,
  qtde,
  updated_at
}: IBaixarRequisicao) {
  return {
    url: `${endpointUrl}/${pedidoId}/requisicao/baixar/${requisicaoId}`,
    method: 'POST',
    body: { requisicaoId, qtde, updated_at }
  };
}

export interface IBaixarRequisicaoLote extends IPedidoID {
  requisicoes: IBaixarRequisicao[];
}

function baixarRequisicaoLote({
  pedidoId,
  requisicoes
}: IBaixarRequisicaoLote) {
  console.log({ pedidoId, requisicoes });
  return {
    url: `${endpointUrl}/${pedidoId}/requisicao/baixarLote`,
    method: 'POST',
    body: { requisicoes }
  };
}

export interface IAlterarSituacaoRequisicaoLote extends IPedidoID {
  situacaoId: number;
  requisicoes: IBaixarRequisicao[];
  previsaoEntrega?: Date;
}

function alterarSituacaoRequisicaoLote({
  pedidoId,
  situacaoId,
  previsaoEntrega,
  requisicoes
}: IAlterarSituacaoRequisicaoLote) {
  return {
    url: `${endpointUrl}/${pedidoId}/requisicao/alterarSituacaoPrevisaoEntregaLote`,
    method: 'POST',
    body: {
      situacaoId,
      previsaoEntrega: previsaoEntrega,
      requisicoes
    }
  };
}

function desfazerBaixarRequisicaoLote({
  pedidoId,
  requisicoes
}: IBaixarRequisicaoLote) {
  console.log({ pedidoId, requisicoes });
  return {
    url: `${endpointUrl}/${pedidoId}/requisicao/desfazerBaixarLote`,
    method: 'POST',
    body: { requisicoes }
  };
}

function baixarPedido({ id }: { id: string }) {
  return { url: `${endpointUrl}/${id}/baixar`, method: 'POST' };
}

const pedidoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getPedidos: query<Pedido[], void>({
      query: getPedidos,
      providesTags: ['Pedidos']
    }),
    getPedido: query<Pedido, { id: string }>({
      query: ({ id }) => getPedido({ id }),
      providesTags: ['Pedidos']
    }),
    findPedidos: mutation<ResultPedidos, FindParams>({
      query: findPedidos,
      invalidatesTags: ['Pedidos']
    }),
    // ### LOG ###
    addLog: mutation<void, { pedidoId: number; updated_at: Date; obs: string }>(
      {
        query: addLog,
        invalidatesTags: ['Pedidos']
      }
    ),
    updLog: mutation<
      void,
      { logId: number; pedidoId: number; updated_at: Date; obs: string }
    >({
      query: updLog,
      invalidatesTags: ['Pedidos']
    }),
    delLog: mutation<
      void,
      { logId: number; pedidoId: number; updated_at: Date }
    >({
      query: delLog,
      invalidatesTags: ['Pedidos']
    }),

    // ### REQUISICAO BAIXAR ###
    baixarRequisicao: mutation<void, IBaixarRequisicao>({
      query: baixarRequisicao,
      invalidatesTags: ['Pedidos']
    }),
    baixarRequisicaoLote: mutation<void, IBaixarRequisicaoLote>({
      query: baixarRequisicaoLote,
      invalidatesTags: ['Pedidos']
    }),
    desfazerBaixarRequisicaoLote: mutation<void, IBaixarRequisicaoLote>({
      query: desfazerBaixarRequisicaoLote,
      invalidatesTags: ['Pedidos']
    }),
    // ### BAIXAR PEDIDO ###
    baixarPedido: mutation<void, { id: string }>({
      query: baixarPedido,
      invalidatesTags: ['Pedidos']
    }),
    // ### ALTERAR SITUACAO REQUISICAO LOTE ###
    alterarSituacaoRequisicaoLote: mutation<
      void,
      IAlterarSituacaoRequisicaoLote
    >({
      query: alterarSituacaoRequisicaoLote,
      invalidatesTags: ['Pedidos']
    })
  })
});

export const {
  useGetPedidoQuery,
  useGetPedidosQuery,
  useFindPedidosMutation,
  useAddLogMutation,
  useUpdLogMutation,
  useDelLogMutation,
  useBaixarRequisicaoMutation,
  useBaixarRequisicaoLoteMutation,
  useDesfazerBaixarRequisicaoLoteMutation,
  useBaixarPedidoMutation,
  useAlterarSituacaoRequisicaoLoteMutation
} = pedidoApiSlice;
