/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CotacaoFornecedor,
  CotacaoItem,
  CotacaoRequisicao
} from '../../models';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import DataGrid, { SortColumn } from 'react-data-grid';
import './styles.css';
import { SaveAlt, Star } from '@mui/icons-material';
import PageLoading from '../../../../components/PageLoading';
import CircularProgressLabel from '../../../../components/CircularProgressLabel';
import {
  getColorByPercentage,
  getColorCircularProgress
} from '../../../../utils/functions';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import PopoverColumns from './popoverColumns';
import { writeFile, utils } from 'xlsx';
import { useSnackbar } from 'notistack';
import ShowPrecoPercentual from './showPrecoPercentual';
import { useEmpresa } from '../../../../hooks/useEmpresa';

interface Props {
  requisicoes: CotacaoRequisicao[];
  items: CotacaoItem[];
  fornecedoresCotacao: CotacaoFornecedor[];
  actionSelectItem: (item: any) => void;
  actionDeselectItem: (item: any) => void;
  loading: boolean;
  exportGrid?: (gridElement: JSX.Element) => void;
  height?: number;
  exportCsv: () => void;
  exportPdf: () => void;
  choiceMenorPreco: () => void;
}

interface Somatoria {
  fornecedorId: number;
  fornecedorNome: string;
  fornecedorCnpj: string;
  pedidoGerado: boolean;
  total: number;
  qtde: number;
  cotados: number;
  naoTenho: number;
  vencedoras: number;
  vencedorasTotal: number;
  selecionados: number;
  selecionadosTotal: number;
}

// make resume row
const resume = (value: Somatoria, fornecedoresCotacao: any[]) => {
  const pedido = fornecedoresCotacao.find(
    (f) => f.fornecedor.id === value.fornecedorId
  )?.pedido;

  return (
    <ListItemText
      primary={
        <Tooltip title={value.fornecedorNome}>
          {/* cnpj e pedido */}
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Typography
              fontSize={8}
              textOverflow={'ellipsis'}
              variant="caption"
              color={'common.gray'}
              align="center"
              noWrap>
              CNPJ: {value.fornecedorCnpj}
            </Typography>
            {pedido && (
              <Typography fontWeight={600} fontSize={10}>
                Ped: {pedido}
              </Typography>
            )}
          </Stack>
        </Tooltip>
      }
      secondary={
        <Box alignItems="center" justifyContent="start" gap={2}>
          <Divider />
          {/* <CircularProgressLabel
            color={getColorCircularProgress(
              Math.round((value.cotados / value.qtde) * 100)
            )}
            value={Math.round((value.cotados / value.qtde) * 100)}
          /> */}

          <Box>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}>
              {/* Selecionadas */}
              <Box display={'flex'} gap={1}>
                <Typography
                  sx={{ color: '#311B92' }}
                  fontSize={10}
                  variant="h6"
                  component="span">
                  Selecionadas:
                </Typography>
                <Typography
                  sx={{ color: '#311B92' }}
                  fontSize={10}
                  fontWeight={'bold'}
                  variant="h6"
                  component="span">
                  {value.selecionados ?? 0}
                </Typography>
              </Box>
              <Box display={'flex'} gap={1}>
                <Typography
                  sx={{ color: '#cc7400' }}
                  fontSize={10}
                  variant="h6"
                  component="span">
                  Falta:
                </Typography>
                <Typography
                  sx={{ color: '#cc7400' }}
                  fontSize={10}
                  fontWeight={'bold'}
                  variant="h6"
                  component="span">
                  {value.naoTenho ?? 0}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}>
              {/* cotadas */}
              <Box display={'flex'} gap={1}>
                <Typography
                  sx={{
                    color: getColorByPercentage(
                      Math.round((value.cotados / value.qtde) * 100)
                    )
                  }}
                  fontSize={10}
                  variant="h6"
                  component="span">
                  Cotados:
                </Typography>
                <Typography
                  sx={{
                    color: getColorByPercentage(
                      Math.round((value.cotados / value.qtde) * 100)
                    )
                  }}
                  fontSize={10}
                  fontWeight={'bold'}
                  variant="h6"
                  component="span">
                  {value.cotados}/{value.qtde}
                </Typography>
              </Box>
              {/* vencedoras */}
              <Box display={'flex'} gap={1}>
                <Typography
                  color={'success.main'}
                  fontSize={10}
                  variant="h6"
                  component="span">
                  Vencedoras:
                </Typography>
                <Typography
                  color={'success.main'}
                  fontSize={10}
                  fontWeight={'bold'}
                  variant="h6"
                  component="span">
                  {value.vencedoras}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}>
              <CircularProgressLabel
                color={getColorCircularProgress(
                  Math.round((value.cotados / value.qtde) * 100)
                )}
                value={Math.round((value.cotados / value.qtde) * 100)}
                size={33}
              />
              {/* total */}
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} gap={1} justifyContent={'space-between'}>
                  <Typography fontSize={10} variant="h6" component="span">
                    {value.selecionadosTotal > 0 ? 'Selecionado:' : 'Comprado:'}
                  </Typography>
                  <Typography
                    fontSize={11}
                    fontWeight={'bold'}
                    variant="h6"
                    component="span">
                    {(
                      value.selecionadosTotal || value.vencedorasTotal
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </Typography>
                </Box>
                <Box display={'flex'} gap={1} justifyContent={'space-between'}>
                  <Typography fontSize={10} variant="h6" component="span">
                    Total:
                  </Typography>
                  <Typography
                    fontSize={11}
                    fontWeight={'bold'}
                    variant="h6"
                    component="span">
                    {value.total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      }
    />
  );
};

export default function DataGridSelection({
  requisicoes,
  items,
  actionSelectItem,
  actionDeselectItem,
  loading = false,
  exportGrid,
  height = 40,
  fornecedoresCotacao,
  exportCsv,
  exportPdf,
  choiceMenorPreco
}: Props) {
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();
  const [rows, setRows] = React.useState<any[]>([]);
  const [somatoria, setSomatoria] = React.useState<any[]>();
  const [columns, setColumns] = React.useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  function selectItem(row: any) {
    actionSelectItem(row);
  }
  function deselectItem(row: any) {
    actionDeselectItem(row);
  }

  const isPedidoGerado = useCallback(
    (fornecedorId: number) => {
      const find = fornecedoresCotacao.find(
        (f) => f.fornecedor.id === fornecedorId
      );
      return find?.pedidoGerado ?? false;
    },
    [fornecedoresCotacao]
  );

  const isRequisicaoComPedido = useCallback(
    (reqId: number) => {
      const find = requisicoes.find((r) => r.requisicao.id === reqId);
      return find?.requisicao?.pedido?.id ?? false;
    },
    [requisicoes]
  );

  const isRequisicaoComVencedor = useCallback(
    (requisicaoId: number) => {
      const filter = items.filter((i) => i.requisicao.id === requisicaoId);
      const some = filter.some((f) => f.situacao.id === 230);
      return some;
    },
    [items]
  );

  const calculaMenorPreco = useCallback(
    (row: any) => {
      const newRow = row;

      const values = Object.values(row);
      const mapObject = values.filter((a) => typeof a === 'object');
      const mapPrecoComImposto = mapObject
        .filter((f: any) => f.situacao?.id === 220 || f.situacao?.id === 230)
        .map((a: any) => a.precoComImposto);

      const minPrecoComImposto = Math.min(...mapPrecoComImposto);

      const mapPreco = mapObject.map((a: any) => {
        const precoComImposto = a.precoComImposto;
        const diferenca = precoComImposto - minPrecoComImposto;
        // const percentual = (diferenca / precoComImposto) * 100;
        const percentual = (precoComImposto * 100) / minPrecoComImposto - 100;

        const _fornecedor = fornecedoresCotacao.find(
          (f) => f.fornecedor.id === a.fornecedorId
        );

        return {
          ...a,
          diferenca,
          percentual,
          fornecedor: _fornecedor?.fornecedor.nome ?? '',
          pedidoGerado: _fornecedor?.pedidoGerado ?? false
        };
      });

      // calcular o menor preço
      // ordenar por menor preco
      const ordenado = mapPreco
        .filter((f) => f.precoComImposto > 0)
        .sort((a, b) => a.precoComImposto - b.precoComImposto);

      // if (ordenado.length > 0) ordenado[0].isMenorPreco = true;
      ordenado.map((a) => {
        if (a.precoComImposto === minPrecoComImposto) {
          a.isMenorPreco = true;
        } else {
          a.isMenorPreco = false;
        }
        return a;
      });

      const mapComMenorPreco = ordenado.map((a: any) => ({
        [a.fornecedor]: a
      }));

      Object.assign(newRow, ...mapComMenorPreco);
      return newRow;
    },
    [fornecedoresCotacao]
  );

  const [visebleColumns, setVisebleColumns] = useLocalStorage<string[]>(
    'data-grid-item-selection-columns',
    ['seuCodigo', 'codigoSgi', 'obs', 'situacao']
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // make columns
  useEffect(() => {
    if (rows.length === 0) return;
    const _keys = Object.keys(rows[0]);

    // reordenar colunas
    const keys = [
      'reqId',
      'codigoSgi',
      'descricao',
      ..._keys.filter(
        (key) =>
          key !== 'reqId' &&
          key !== 'codigoSgi' &&
          key !== 'descricao' &&
          key !== 'updated_at'
      ),
      'updated_at'
    ];
    const cols: any[] = [];
    keys.forEach((key) => {
      switch (key) {
        case 'reqId':
          cols.push({
            key: 'reqId',
            name: 'Req.',
            width: 65,
            headerCellClass: 'ajustar-fonte'
          });
          break;

        case 'codigoSgi':
          cols.push({
            key: 'codigoSgi',
            name: 'Código SGI',
            width: 100,
            headerCellClass: 'ajustar-fonte'
          });
          break;

        case 'descricao':
          cols.push({
            key: 'descricao',
            name: 'Descrição',
            width: 300,
            maxWidth: 600,
            minWidth: 250,
            resizable: true,
            headerCellClass: 'ajustar-fonte'
          });
          break;

        case 'qtde':
          cols.push({
            key: 'qtde',
            name: 'Qtde',
            width: 70,
            cellClass: 'text-right',
            headerCellClass: 'ajustar-fonte'
          });
          break;

        case 'situacao':
          // cols.push({
          //   key: 'situacao',
          //   name: 'Situação',
          //   width: 100,
          //   frozen: true
          // });
          break;
        case 'updated_at':
          break;
        default:
          cols.push({
            key,
            name: key,
            resizable: true,
            width: 200,
            maxWidth: 400,
            minWidth: 200,
            headerCellClass: 'multiline-header ajustar-fonte',
            renderCell({ row }: any) {
              // console.log('🚀 ~ file: index.tsx:202 ~ renderCell ~ row', row);
              // não tenho
              if (row[key].situacao.id === 215) {
                return (
                  <div
                    style={{
                      display: 'block',
                      color: '#cc7400',
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    Não Tem
                  </div>
                );
              }

              // cotada e com vencedor
              if (
                row[key].situacao.id === 220 &&
                isRequisicaoComVencedor(row.reqId)
              ) {
                return (
                  <ShowPrecoPercentual
                    item={row[key]}
                    showMarcador={false}
                    novaCor="#9E9E9E"
                  />
                );
              }

              // cotada e fornecedor sem pedido gerado
              if (
                row[key].situacao.id === 220 &&
                isPedidoGerado(row[key].fornecedorId) === false
              ) {
                return (
                  <ShowPrecoPercentual
                    item={row[key]}
                    selectItem={selectItem}
                    deselectItem={deselectItem}
                  />
                );
              }

              // cotada e fornecedor com pedido gerado
              if (
                row[key].situacao.id === 220 &&
                isPedidoGerado(row[key].fornecedorId) !== false
              ) {
                return (
                  <ShowPrecoPercentual item={row[key]} showMarcador={false} />
                );
              }

              // vencedora
              if (row[key].situacao.id === 230) {
                return (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '2fr 1fr 1fr',
                      gap: '0.5em',
                      justifyItems: 'center',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <strong
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        color: row[key].isMenorPreco ? '#46c346' : '#c34a46'
                      }}>
                      {row[key].precoComImposto.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </strong>

                    <span
                      style={{
                        fontSize: '0.7em',
                        color: row[key].percentual === 0 ? '#46c346' : '#c34a46'
                      }}>
                      {`${
                        row[key].percentual > 0
                          ? ' ⇡ ' +
                            row[key].percentual.toLocaleString('pt-BR', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                            }) +
                            '%'
                          : ''
                      }`}
                    </span>

                    <span style={{ textAlign: 'right' }}>
                      <Tooltip title="Item vencedor" arrow>
                        <button
                          style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'not-allowed',
                            padding: '5px'
                          }}>
                          <Star color="success" fontSize="small" />
                        </button>
                      </Tooltip>
                    </span>
                  </div>
                );
              }

              // não cotada
              return (
                <div
                  style={{
                    fontSize: '0.7em',
                    textAlign: 'center',
                    color: '#c0c0c0'
                  }}>
                  Não Cotada
                </div>
              );
            },
            renderSummaryCell({ row }: { row: Somatoria[] }) {
              if (!row) return <></>;
              const value = row.find((r) => r.fornecedorNome === key);
              if (!value) return <></>;
              return resume(value, fornecedoresCotacao);
            }
          });
      }
    });

    // console.log('🚀 ~ file: index.tsx:150 ~ useEffect ~ helper:', helper);
    setColumns(cols);
    // console.log('🚀 ~ file: index.tsx:103 ~ useEffect ~ cols:', cols);
    // console.log({ cols });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  // make rows
  useEffect(() => {
    const data = requisicoes.map((req) => {
      const newReq = req;
      const item = items.filter(
        (item) => item.requisicao.id === newReq.requisicao.id
      );

      const map = item.map((item) => ({
        fornecedorId: item.fornecedor.id,
        fornecedor: item.fornecedor.nome,
        cotacao: {
          itemId: item.id,
          situacao: item.situacao,
          fornecedorId: item.fornecedor.id,
          preco: item.preco,
          precoComImposto: item.precoComImposto,
          isSelected: item.isSelected,
          qtde: item.qtde,
          isMenorPreco: item.isMenorPreco
        }
      }));
      // console.log('🚀 ~ file: index.tsx:26 ~ map ~ map:', map);
      const subObj = {};
      map.forEach((m) => {
        const t = m.fornecedor as unknown as string;
        return Object.assign(subObj, { [t]: m.cotacao });
      });

      // se precisar adicionar mais campos no objeto requisicao: aqui!!!!
      return {
        reqId: newReq.requisicao.id,
        descricao: newReq.requisicao.descricaoProdutoServico,
        codigoSgi: newReq.requisicao?.produtoServico?.codigoSgi,
        updated_at: newReq.updatedAt,
        qtde:
          qtdeDigitosCampoQtde > 2
            ? newReq.requisicao.qtde.toLocaleString('pt-br', {
                minimumFractionDigits: qtdeDigitosCampoQtde,
                maximumFractionDigits: qtdeDigitosCampoQtde
              })
            : newReq.requisicao.qtde.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: qtdeDigitosCampoQtde
              }),
        ...subObj
      };
    });

    // fazer um map calculando a diferenca de preco em relacao ao menor preco mostrando em percentual
    const map = data.map((d: any) => ({
      ...d,
      ...calculaMenorPreco(d)
    }));

    setRows(map.reverse());
  }, [requisicoes, items, calculaMenorPreco]);

  // sort rows
  const getComparator = useCallback((sortColumn: string) => {
    switch (sortColumn) {
      //-> string sort
      case 'descricao':
        return (a: any, b: any) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };

      //-> boolean sort
      // case '':
      //   return (a, b) => {
      //     return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      //   };

      //-> date sort
      // case 'previsao':
      //   return (a: any, b: any) => {
      //     return (
      //       new Date(a[sortColumn]).getTime() -
      //       new Date(b[sortColumn]).getTime()
      //     );
      //   };

      case 'qtde':
      case 'codigoSgi':
      case 'reqId': // => number
        return (a: any, b: any) => {
          return Number(a[sortColumn]) - Number(b[sortColumn]);
        };
      default:
        enqueueSnackbar(`Não é possível ordenar a coluna: "${sortColumn}"`, {
          variant: 'error'
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const sortedRows = useMemo((): readonly any[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        if (!comparator) {
          return 0;
        }
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [getComparator, rows, sortColumns]);

  // calcular somatoria de total por fornecedor
  useEffect(() => {
    const fornecedoresSET = new Set();
    items.map((item) => {
      fornecedoresSET.add(item.fornecedor);
      return item.fornecedor;
    });

    const map: any[] = [];
    fornecedoresSET.forEach((fornecedor: any) => {
      const filter = items.filter(
        (item) => item.fornecedor.id === fornecedor.id
      );
      const mapItem = filter.map((item) => ({
        // fornecedorId: item.fornecedor.id,
        // fornecedorNome: item.fornecedor.nome,
        // fornecedorCnpj: item.fornecedor.cnpj,
        total: item.precoComImposto * item.qtde,
        cotados: item.situacao.id !== 210 ? 1 : 0,
        naoTenho: item.situacao.id === 215 ? 1 : 0,
        vencedoras: item.situacao.id === 230 ? 1 : 0,
        vencedorasTotal:
          item.situacao.id === 230 ? item.precoComImposto * item.qtde : 0,
        selecionados:
          item.isSelected === true &&
          item.situacao.id === 220 &&
          isPedidoGerado(fornecedor.id) === false &&
          isRequisicaoComVencedor(item?.requisicao?.id ?? 0) === false
            ? 1
            : 0,
        selecionadosTotal:
          item.isSelected === true &&
          item.situacao.id === 220 &&
          isPedidoGerado(fornecedor.id) === false &&
          isRequisicaoComVencedor(item?.requisicao?.id ?? 0) === false
            ? item.precoComImposto * item.qtde
            : 0
      }));

      const total = mapItem.reduce((acc, cur) => acc + cur.total, 0);
      const cotados = mapItem.reduce((acc, cur) => acc + cur.cotados, 0);
      const naoTenho = mapItem.reduce((acc, cur) => acc + cur.naoTenho, 0);
      const vencedoras = mapItem.reduce((acc, cur) => acc + cur.vencedoras, 0);
      const selecionados = mapItem.reduce(
        (acc, cur) => acc + cur.selecionados,
        0
      );
      const selecionadosTotal = mapItem.reduce(
        (acc, cur) => acc + cur.selecionadosTotal,
        0
      );

      const vencedorasTotal = mapItem.reduce(
        (acc, cur) => acc + cur.vencedorasTotal,
        0
      );
      const reg = {
        fornecedorId: fornecedor.id,
        fornecedorNome: fornecedor.nome,
        fornecedorCnpj: fornecedor.cnpj,
        pedidoGerado: fornecedor.pedidoGerado,
        total,
        qtde: mapItem.length,
        cotados,
        naoTenho,
        vencedoras,
        vencedorasTotal,
        selecionados,
        selecionadosTotal
      };

      const find = map.find((m) => m.fornecedorId === fornecedor.id);
      if (!find) {
        map.push(reg);
      }
    });

    setSomatoria(map);
  }, [
    items,
    columns,
    rows,
    isRequisicaoComPedido,
    isPedidoGerado,
    isRequisicaoComVencedor
  ]);

  const mapToExcel = ((rows as any[]) ?? []).map((d) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { reqId, codigoSgi, descricao, qtde, ...rest } = d;

    const entries = Object.entries(rest);

    const map = entries.map((e) => {
      const [key, value] = e;
      if (key !== 'updated_at') {
        return {
          [key]: (value as any).precoComImposto || 0
        };
      }
    });

    const ret = {
      Req: d.reqId,
      'Código SGI': d.codigoSgi,
      Descrição: d.descricao,
      Qtde: d.qtde
    };
    Object.assign(ret, ...map);

    return ret;
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Melhor-Preco.xlsx');
  }

  function rowKeyGetter(row: any): any {
    return row.reqId;
  }

  const gridElement = useMemo(
    () => (
      <DataGrid
        className="rdg-light"
        style={{ height: `${height}vh`, width: '100%' }}
        // columns={columns}
        columns={columns.filter(
          (column) => visebleColumns.indexOf(column.key) === -1
        )}
        rows={sortedRows}
        defaultColumnOptions={{
          sortable: true
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={setRows}
        headerRowHeight={50}
        // onCellKeyDown={(args, event) =>
        //   handleCellKeyDown(args, event, rows, 'CHANGE_ROW')
        // }
        bottomSummaryRows={[somatoria]}
        // topSummaryRows={[somatoria] ?? []}
        summaryRowHeight={90}
      />
    ),
    [columns, height, somatoria, sortColumns, sortedRows, visebleColumns]
  );

  useEffect(() => {
    if (exportGrid && gridElement) {
      exportGrid(gridElement);
    }
  }, [exportGrid, gridElement]);

  if (loading) <PageLoading minHeight={60} />;
  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} mb={1}>
        <Box display="flex" gap={1}>
          <PopoverColumns
            value={visebleColumns}
            onChange={setVisebleColumns}
            activeColumns={['codigoSgi', 'reqId', 'qtde', 'descricao']}
            columns={columns}
          />
          <Box>
            <IconButton size="small" color="primary" onClick={toggleOpen}>
              <SaveAlt />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={'bottom-start'}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List>
                      <ListItemButton onClick={handleExport}>
                        Exportar Excel
                      </ListItemButton>
                      <Divider />
                      <ListItemButton onClick={exportPdf}>
                        Exportar PDF
                      </ListItemButton>
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </Box>
        </Box>
        <Button
          onClick={choiceMenorPreco}
          size="small"
          variant="contained"
          color="primary">
          Selecionar menores preços
        </Button>
      </Box>
      {gridElement}
    </Box>
  );
}
