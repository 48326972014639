import { number, object, string } from 'yup';
export interface ProdutoCadastro {
  id?: number;
  codigoSgi: string;
  descricao: string;
  descricaoCompleta: null;
  referencia: string;
  unidade: string;
  preco: number;
  peso: number;
  exibeNaPesquisa: number;
  created_at: Date;
  updated_at: Date;
  classe: Classe;
  images: any[];
}

interface Classe {
  id: number;
  descricao: string;
}

export interface Produto {
  id?: number;
  codigo: string;
  codigobarras: string;
  descricao: string;
  unidade: string;
  referencia: string;
  codigofabricante: string;
  saldo_reservado: number;
  saldo_previsto: number;
  estmin: number;
  localizacao: null;
  filial: string;
  local: string;
  saldo_atual: number;
  datacadastro: Date;
  dhua: Date;
  tipocontrolelote: string;
  peso: number;
  qtde: number;
  areservar: number;
  saldofinal: number;
  id_grupo: number;
  fabricante: string;
  precocusto: number;
  precovenda: number;
  classe: string;
  categoria: number;
  custo_atual: number;
  descricaosite: null;
  ativo: string;
  ncm: string;
  oproduto: string;
  ofabricantes: string;
  ocodigosfabricante: string;
  ispromocao: string;
  qtdeempromocao: null;
  precopromocional: null;
  produtosabaixominimo: string;
}

export interface ProdutoLote {
  codigoSgi: string;
  descricao: string;
  descricaoCompleta: string;
  referencia: string;
  unidade: string;
  preco: number;
  peso: number;
  classeId: number;
}

export interface LastMovim {
  id: number;
  tipomov: string;
  tipomovdesc: string;
  data: string;
  especie: string;
  serie: string;
  nfiscal: string;
  qtde: number;
  pcusto: number;
  nome: string;
  apelido: string;
  cnpj: string;
  ipi: number;
  valoripi: number;
  valoricmsst: number;
}

export interface IProdutoFilters {
  classeId: string[];
  searchFor: string;
}

export const PRODUTO_INITIAL_FILTERS: IProdutoFilters = {
  classeId: [],
  searchFor: ''
};

export const PRODUTO_INITIAL_FORM: Partial<ProdutoCadastro> = {
  id: 0,
  codigoSgi: '',
  descricao: '',
  descricaoCompleta: null,
  referencia: '',
  unidade: '',
  preco: 0,
  peso: 0,
  exibeNaPesquisa: 0
};

export const PRODUTO_VALIDATION_FORM = object({
  codigoSgi: string().notRequired(),
  descricao: string().required('A descrição é obrigatória'),
  referencia: string().required('A referência é obrigatória'),
  unidade: string().required('A unidade é obrigatória'),
  preco: number().required('O preço é obrigatório'),
  peso: number().required('O peso é obrigatório'),
  exibeNaPesquisa: number().notRequired()
});
