/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useSnackbar } from 'notistack';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import {
  useFindPedidosMutation,
  useBaixarRequisicaoMutation,
  useBaixarRequisicaoLoteMutation,
  useDesfazerBaixarRequisicaoLoteMutation,
  useBaixarPedidoMutation,
  IBaixarRequisicao,
  IBaixarRequisicaoLote,
  useAlterarSituacaoRequisicaoLoteMutation,
  IAlterarSituacaoRequisicaoLote
} from '../pedido/apiSlice';
import {
  IPedidoFilters,
  PEDIDO_INITIAL_FILTERS,
  Pedido
} from '../pedido/models';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';

export type TPedidoParams = {
  take: number;
  skip: number;
  orderBy: string;
  sort: string;
};

export const INITIAL_PARAMS: TPedidoParams = {
  take: 10,
  skip: 0,
  orderBy: '',
  sort: '1'
};

interface IContext {
  loading: boolean;
  data: Pedido[] | undefined;
  // filtro
  filterPedidos: () => void;
  filters: IPedidoFilters;
  setFilters: (filters: IPedidoFilters) => void;
  filterParams: TPedidoParams;

  // pesquisa
  searchPedidos: (serachFor: string | undefined) => void;
  searchFor: string;
  searchParams: TPedidoParams;

  // filtro e pesquisa
  setParams: (params: TPedidoParams) => void;
  paramsQtdeRows: number;
  paramsQtdePage: number;

  // tipo de busca: filtro ou pesquisa
  type: 'filter' | 'search';
  setType: (type: 'filter' | 'search') => void;
  reload: () => void;

  // baixar requisição
  baixarRequisicao: (params: IBaixarRequisicao) => Promise<void>;
  baixarRequisicaoLote: (params: IBaixarRequisicaoLote) => Promise<void>;
  desfazerBaixarRequisicaoLote: (
    params: IBaixarRequisicaoLote
  ) => Promise<void>;

  // baixar pedido
  baixarPedido: (id: string) => Promise<void>;

  // alterar situação da requisição
  alterarSituacaoRequisicaoLote: (
    params: IAlterarSituacaoRequisicaoLote
  ) => Promise<void>;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [findPedido] = useFindPedidosMutation();
  const [baixarRequisicaoMutation] = useBaixarRequisicaoMutation();
  const [baixarRequisicaoLoteMutation] = useBaixarRequisicaoLoteMutation();
  const [baixarPedidoMutation] = useBaixarPedidoMutation();
  const [desfazeBaixarRequisicaoLoteMutation] =
    useDesfazerBaixarRequisicaoLoteMutation();
  const [alterarSituacaoRequisicaoLoteMutation] =
    useAlterarSituacaoRequisicaoLoteMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const data = useRef<Pedido[] | undefined>(undefined);

  // tipo de busca: filtro ou pesquisa
  const typeCurrent = useRef<'filter' | 'search'>('filter');
  const setType = (_type: 'filter' | 'search') => {
    typeCurrent.current = _type as 'filter' | 'search';
    if (_type === 'filter') {
      searchParamsCurrent.current = {
        ...searchParamsCurrent.current,
        skip: 0
      };
    } else {
      filterParamsCurrent.current = {
        ...filterParamsCurrent.current,
        skip: 0
      };
    }
  };

  // filtros
  const [filters, setFilters] = useLocalStorage<IPedidoFilters>(
    'pedido-filtros',
    PEDIDO_INITIAL_FILTERS
  );
  const lastFilters = useRef<IPedidoFilters>(PEDIDO_INITIAL_FILTERS);

  // pesquisa
  const searchFor = useRef<string>('');
  const lastSearchFor = useRef<string>('');

  // parâmetros do filtro
  const filterParamsCurrent = useRef<TPedidoParams>({
    ...INITIAL_PARAMS
  });
  const filterParamsQtdeRows = useRef<number>(0);
  const filterParamsQtdePage = useRef<number>(0);

  // parâmetros da pesquisa
  const searchParamsCurrent = useRef<TPedidoParams>({
    ...INITIAL_PARAMS
  });
  const searchParamsQtdeRows = useRef<number>(0);
  const searchParamsQtdePage = useRef<number>(0);

  // seta os parâmetros de acordo com o tipo de busca
  const setParams = (params: TPedidoParams) => {
    if (typeCurrent.current === 'filter') {
      filterParamsCurrent.current = {
        ...filterParamsCurrent.current,
        ...params
      };
      filterPedidos();
    } else {
      searchParamsCurrent.current = {
        ...searchParamsCurrent.current,
        ...params
      };
      searchPedidos();
    }
  };

  // busca pedidos de acordo com o filtro
  const filterPedidos = useCallback(async () => {
    try {
      setLoading(true);
      typeCurrent.current = 'filter';
      // reseta data e parâmetros
      data.current = [];
      filterParamsQtdeRows.current = 0;
      filterParamsQtdePage.current = 0;

      const entries = Object.entries(filters);

      const args = {};
      entries.forEach(([key, value]) => {
        if (value) {
          if (key === 'situacaoId') {
            if (value.length === 0) return;
            Object.assign(args, {
              [key]: value.reduce((acc: string[], cur: string) => {
                acc.push(`'${cur}'`);
                return acc;
              }, [])
            });
            return;
          }

          if (key === 'fornecedorId') {
            Object.assign(args, {
              [key]: [value]
            });
            return;
          }

          if (key === 'created_at') {
            if (!value[0] && !value[1]) return;

            const ini = value[0];
            const end = value[1];

            Object.assign(args, {
              [key]: [ini, end]
            });
            return;
          }
        }
      });

      let fParams = filterParamsCurrent.current;
      if (!isEqual(lastFilters.current, filters)) {
        fParams = {
          ...filterParamsCurrent.current,
          skip: 0
        };
      }
      filterParamsCurrent.current = fParams;

      const params = { ...fParams, body: args };
      const result = await findPedido(params);
      if (!result) {
        data.current = [];
        filterParamsQtdeRows.current = 0;
        filterParamsQtdePage.current = 0;
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rows = (result as any).data.pedidos;
      const qtdeRegistros = (result as any).data.info.registros;
      const qtdePorPagina =
        Number((result as any).data?.info?.qtdePorPagina) ?? 0;
      const pages = Math.ceil(qtdeRegistros / qtdePorPagina);
      filterParamsQtdeRows.current = qtdeRegistros;
      filterParamsQtdePage.current = pages;
      data.current = rows as Pedido[];
      lastFilters.current = filters;
      // reseta a pesquisa
      searchFor.current = '';
    } catch (error) {
      data.current = [];
    } finally {
      setLoading(false);
    }
  }, [filters, findPedido]);

  // busca pedidos de acordo com a pesquisa
  const searchPedidos = useCallback(
    async (_searchFor: string | undefined = undefined) => {
      try {
        setLoading(true);
        // reseta data e parâmetros
        data.current = [];
        typeCurrent.current = 'search';
        searchParamsQtdeRows.current = 0;
        searchParamsQtdePage.current = 0;

        let searchText;
        if (_searchFor) {
          searchText = _searchFor;
          searchFor.current = searchText;
        } else {
          searchText = searchFor.current;
        }

        let fParams = searchParamsCurrent.current;
        if (!isEqual(lastSearchFor.current, searchText)) {
          fParams = {
            ...searchParamsCurrent.current,
            skip: 0
          };
        }

        const params = {
          ...fParams,
          body: {
            searchFor: searchText
          }
        };

        const result = await findPedido(params);
        if (!result) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rows = (result as any).data.pedidos;
        const qtdeRegistros = (result as any).data?.info?.registros ?? 0;
        const qtdePorPagina =
          Number((result as any).data?.info?.qtdePorPagina) ?? 0;
        const pages = Math.ceil(qtdeRegistros / qtdePorPagina);
        searchParamsQtdeRows.current = qtdeRegistros;
        searchParamsQtdePage.current = pages;
        data.current = rows as Pedido[];
        lastSearchFor.current = searchFor.current;
        searchParamsCurrent.current = fParams;
        // reseta o filtro
        // setFilters(PEDIDO_INITIAL_FILTERS);
      } catch (error) {
        data.current = [];
      } finally {
        setLoading(false);
      }
    },
    [findPedido]
  );

  const reload = () => {
    if (typeCurrent.current === 'filter') {
      filterPedidos();
    } else {
      searchPedidos();
    }
  };

  // baixar requisição
  const baixarRequisicao = async (params: IBaixarRequisicao) => {
    setLoading(true);
    baixarRequisicaoMutation(params)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Requisição recebida com sucesso', {
          variant: 'success'
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.data.message, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
        // reload();
      });
  };

  const baixarRequisicaoLote = async (params: IBaixarRequisicaoLote) => {
    setLoading(true);
    baixarRequisicaoLoteMutation({
      pedidoId: params.pedidoId,
      requisicoes: params.requisicoes
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Requisições recebidas com sucesso', {
          variant: 'success'
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.data.message, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
        // reload();
      });
  };

  const desfazerBaixarRequisicaoLote = async (
    params: IBaixarRequisicaoLote
  ) => {
    setLoading(true);
    desfazeBaixarRequisicaoLoteMutation({
      pedidoId: params.pedidoId,
      requisicoes: params.requisicoes
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Requisições recebidas com sucesso', {
          variant: 'success'
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.data.message, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
        // reload();
      });
  };

  const baixarPedido = async (_id: string) => {
    setLoading(true);
    baixarPedidoMutation({ id: _id })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Pedido recebido com sucesso', {
          variant: 'success'
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.data.message, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
        // reload();
      });
  };

  const alterarSituacaoRequisicaoLote = async (
    params: IAlterarSituacaoRequisicaoLote
  ) => {
    setLoading(true);
    console.log(params);
    alterarSituacaoRequisicaoLoteMutation(params)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Requisições recebidas com sucesso', {
          variant: 'success'
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.data.message, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
        // reload();
      });
  };

  // useEffect(() => {
  //   filterPedidos();
  // }, [filterPedidos]);

  return (
    <Context.Provider
      value={{
        loading,
        data: data.current,
        // filtro
        filterPedidos,
        filters,
        setFilters,
        filterParams: filterParamsCurrent.current,

        // pesquisa
        searchPedidos,
        searchFor: searchFor.current,
        searchParams: searchParamsCurrent.current,

        // filtro e pesquisa
        setParams,
        paramsQtdeRows:
          typeCurrent.current === 'filter'
            ? filterParamsQtdeRows.current
            : searchParamsQtdeRows.current,
        paramsQtdePage:
          typeCurrent.current === 'filter'
            ? filterParamsQtdePage.current
            : searchParamsQtdePage.current,

        // tipo de busca: filtro ou pesquisa
        type: typeCurrent.current,
        setType,
        reload,
        // baixar requisição
        baixarRequisicao,
        baixarRequisicaoLote,
        desfazerBaixarRequisicaoLote,
        // baixar pedido
        baixarPedido,
        // alterar situação da requisição
        alterarSituacaoRequisicaoLote
      }}>
      {children}
    </Context.Provider>
  );
};

const usePedido = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { usePedido, Provider as PedidoProvider };
