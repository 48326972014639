import React, { useState } from 'react';
import { OUTLET_HEIGHT } from '../../../config/theme';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  styled
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import AppConfig from '../../../config/app';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useGetPedidoQuery } from '../../pedido/apiSlice';
import TabPanelInfo from './tabPanelInfo';
import TabPanelRequisicao from './tabPanelRequisicao';
import TabPanelLogs from './tabPanelLogs';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useCotacaoRetransmitirMutation } from '../../cotacao/apiSlice';
import { useSnackbar } from 'notistack';
import ConfirmModal from '../../../components/ConfirmModal';
import SelectSituacaoModal from '../../../components/SelectSituacaoModal';
// import { usePedido } from '../context';

const Inner = styled('div')(() => ({
  height: OUTLET_HEIGHT
}));

const TAB_SX = (theme: Theme, valueTab: number, index: number) => ({
  textTransform: 'none',
  fontSize: '0.8rem',
  fontWeight: valueTab === index ? 'bold' : 'inherit',
  textAlign: 'left',
  flex: 1,
  bgcolor:
    valueTab === index ? theme.palette.secondary.main : theme.palette.grey[100]
});

export default function PendenciaEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const [cotacaoRetransmitir] = useCotacaoRetransmitirMutation();
  const id = useParams().id as string;
  const { data: pedido, isLoading } = useGetPedidoQuery({ id });
  // const { baixarPedido } = usePedido();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const retransmitir = () => {
    if (!pedido) return;
    cotacaoRetransmitir(pedido.id)
      .unwrap()
      .then((data) => {
        console.log(data);
        enqueueSnackbar('Pedido retransmitido com sucesso', {
          variant: 'success'
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.data.message ?? 'Erro ao retransmitir pedido', {
          variant: 'error'
        });
      });
  };

  // const handlerBaixarPedido = () => {
  //   if (!id) return;
  //   baixarPedido(id);
  // };

  async function handleAlerarSituacaoRequisicaoLote(situacaoId: number) {
    const params = {
      pedidoId: id,
      situacaoId: situacaoId
    };

    // await alterarSituacaoRequisicaoLote(params as any);
  }

  return (
    <Inner>
      <Box
        py={1}
        display="flex"
        alignItems={'center'}
        justifyContent="space-between">
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Link color="inherit" to={AppConfig.routes.cadPendencia}>
            <Typography>Pendências</Typography>
          </Link>
          <Typography>Alterar</Typography>
          <Typography>Pedido({pedido?.id})</Typography>
          <Chip label={pedido?.fornecedor?.nome} />
        </Breadcrumbs>
        <Box display={'flex'} gap={2}>
          {!pedido?.transmitido && (
            <ConfirmModal
              title="Retransmitir Pedido"
              description={`Confirmar retransmissão do pedido: ${pedido?.id} do fornecedor ${pedido?.fornecedor.nome}?`}
              action={retransmitir}
              component={
                <Button
                  type="button"
                  startIcon={<IosShareIcon />}
                  color="success"
                  variant="contained">
                  Retransmitir
                </Button>
              }
            />
          )}
          <Button
            type="button"
            component={Link}
            to={AppConfig.routes.cadPendencia}
            startIcon={<ReplyAllIcon />}
            variant="outlined">
            Voltar
          </Button>
          {/* <ConfirmModal
            title="Receber Todas"
            description={`Confirmar recebimento do pedido todo: ${pedido?.id} \nfornecedor ${pedido?.fornecedor.nome}?`}
            action={handlerBaixarPedido}
            component={
              <Button
                type="button"
                startIcon={<SystemUpdateAlt />}
                color="success"
                variant="contained">
                Receber Todas
              </Button>
            }
          /> */}
          {/* <SelectSituacaoModal
            key={0}
            data={{
              previsao: pedido?.previsao
                ? new Date(pedido?.previsao)
                : undefined,
              situacao: pedido?.situacao.id
            }}
            situacoes={[]
              .filter((s: any) => s.id === 4 || s.id === 6)
              .map((el: any) => ({
                id: el.id!,
                desc: el.nome.toUpperCase(),
                corFundo: el.corFundo,
                corLetra: el.corLetra
              }))}
            action={(data) => {
              // aguardar api para alterar situação
              // console.log(situacaoId);
              console.log('Alterar situação pedido dados:', data);
            }}
            component={
              <Button key={0} color="primary" variant="contained">
                Alterar Situação Pedido
              </Button>
            }
          /> */}
        </Box>
      </Box>
      <Paper
        sx={(theme) => ({
          height: '78vh',
          padding: '10px 0'
          // backgroundColor:
          //   pedido?.situacao.id !== 3
          //     ? theme.palette.grey[200]
          //     : theme.palette.background.paper
        })}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ hidden: true }}
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.main,
              height: 3
            },
            '& button.Mui-selected': {
              color: theme.palette.common.white!
            }
          })}>
          <Tab sx={(theme) => TAB_SX(theme, value, 0)} label="Informações" />
          <Tab sx={(theme) => TAB_SX(theme, value, 1)} label="Requisições" />
          <Tab sx={(theme) => TAB_SX(theme, value, 2)} label="Interações" />
        </Tabs>
        {pedido && <TabPanelInfo value={value} index={0} pedido={pedido} />}
        {pedido && (
          <TabPanelRequisicao
            value={value}
            index={1}
            pedido={pedido}
            loading={isLoading}
          />
        )}
        {pedido && <TabPanelLogs value={value} index={2} pedido={pedido} />}
      </Paper>
    </Inner>
  );
}
