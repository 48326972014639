import { useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Pagination,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  alpha,
  IconButton
} from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
// import { GridToolbar } from '@mui/x-data-grid/components';
import { Log, Pedido } from '../pedido/models';
import { useNavigate } from 'react-router-dom';
import { TPedidoParams, usePedido } from './context';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { CustomNoTableRowsOverlay } from '../../components/NoCustomRowOverlay';
import AppConfig from '../../config/app';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';

interface Props {
  data: Pedido[];
  isLoading: boolean;
  selectedRows?: (selectedRowsData: Pedido[]) => void;
}

export default function TablePedido({ data, isLoading, selectedRows }: Props) {
  const navigate = useNavigate();
  const {
    filterParams,
    searchParams,
    setParams,
    paramsQtdeRows,
    paramsQtdePage,
    type
  } = usePedido();

  const [pageSize, setPageSize] = useLocalStorage('table-pedido-pagesize', 10);

  function pageSizeChange(newPageSize: number) {
    updateParams({ take: newPageSize, skip: 0 });
    setPageSize(newPageSize);
  }

  function mapDataToGridRows() {
    if (!data?.length) return [];
    return data.map((d) => ({
      id: d.id,
      createdAt: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      situacao: d.situacao,
      fornecedor: d.fornecedor,
      cnpj: d.fornecedor?.cnpj,
      cotacao: '', // linkar quando tiver o campo
      transmissao: d.transmissao,
      transmitido: d.transmitido,
      movimento: d.movimento,
      obs:
        d.logs.reduce((acc: Log, log: Log) => {
          acc = log;
          if (new Date(log.createdAt) > new Date(log.createdAt)) {
            acc = log;
          }
          return acc;
        })?.obs ?? '',
      reqs: d.reqs.length
    }));
  }

  const updateParams = useCallback(
    (param: Partial<TPedidoParams>) => {
      setParams({ ...filterParams, ...param });
    },
    [filterParams, setParams]
  );

  useEffect(() => {
    if (type === 'filter') {
      if (pageSize !== filterParams.take) updateParams({ take: pageSize });
    } else {
      if (pageSize !== searchParams.take) updateParams({ take: pageSize });
    }
  }, [filterParams.take, pageSize, searchParams.take, type, updateParams]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columnsPedidoPendenciaVisibles',
      {}
    );

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        {/* <GridToolbarExport variant="text" /> */}
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  function CustomPagination() {
    // const apiRef = useGridApiContext();
    // const rows = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}
        {/* <Button
          onClick={handlerAprovar}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DoneAllIcon />}>
          Marcar Recebido
        </Button> */}

        {/* {type === 'filter' ? (
          <Chip icon={<FilterAltIcon />} label="Por Filtro" size="small" />
        ) : (
          <Chip icon={<SearchIcon />} label="Por Texto" size="small" />
        )} */}
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontSize={12}>Qtde/página:</Typography>
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'row' }}
            name="controlled-radio-buttons-group"
            value={pageSize.toString()}
            onChange={(e) => pageSizeChange(Number(e.target.value))}>
            <FormControlLabel
              value="10"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="10"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="25"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="25"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="50"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="50"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
          </RadioGroup>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Pagination
            sx={{
              '.MuiPaginationItem-rounded': {
                color: '#fff'
              }
            }}
            shape="rounded"
            color="primary"
            count={paramsQtdePage}
            page={
              type === 'filter' ? filterParams.skip + 1 : searchParams.skip + 1
            }
            onChange={(event, value) => {
              let params: Partial<TPedidoParams> = {};
              if (type === 'filter') {
                params = {
                  ...filterParams,
                  skip: value - 1,
                  take: pageSize
                };
              } else {
                params = {
                  ...searchParams,
                  skip: value - 1,
                  take: pageSize
                };
              }
              setParams(params as TPedidoParams);
            }}
            showFirstButton
            showLastButton
          />
          <Typography fontSize={12}>Qtde: {paramsQtdeRows}</Typography>
        </Box>
      </Box>
    );
  }

  // function handlePageChange(page: number, details: GridCallbackDetails<any>) {
  //   console.log('handlePageChange', page, details);
  // }

  const mapToExcel = data.map((d) => ({
    Pedido: d.id,
    Data: d.createdAt ? new Date(d.createdAt).toLocaleDateString('pt-BR') : '',
    Situação: d.situacao?.nome,
    Fornecedor: d.fornecedor?.nome,
    Transmissão: d.transmissao
      ? new Date(d.transmissao).toLocaleDateString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit'
        })
      : '',
    Transmitido: d.transmitido ? 'Sim' : 'Não',
    Movimento: d.movimento,
    'Qtde Requisições': d.reqs.length,
    Obs:
      d.logs.reduce((acc: Log, log: Log) => {
        acc = log;
        if (new Date(log.createdAt) > new Date(log.createdAt)) {
          acc = log;
        }
        return acc;
      })?.obs ?? ''
  }));

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'CotacaoRequisicoes.xlsx');
  }

  return (
    <Paper>
      <Box height="80vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 13,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& .super-app-theme--true': {
              bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.success.main
              }
            },
            '& .super-app-theme--false': {
              bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.warning.main
              }
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          loading={isLoading}
          // paginationMode="server"
          rows={mapDataToGridRows() ?? []}
          density="compact"
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              align: 'center',
              width: 60,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'createdAt',
              headerName: 'Data',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'situacao',
              headerName: 'Situação',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value.nome}`,
              renderCell: (params) => {
                return (
                  <Typography
                    fontSize={10}
                    sx={(theme) => ({
                      color: params.row.situacao?.corLetra || '#000000',
                      background: params.row.situacao?.corFundo,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.5),
                      borderRadius: theme.shape.borderRadius
                    })}>
                    {params.row.situacao?.nome}
                  </Typography>
                );
              }
            },
            {
              field: 'cotacao',
              headerName: 'Cotação',
              minWidth: 250,
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'cnpj',
              headerName: 'CNPJ',
              align: 'right',
              minWidth: 180,
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'fornecedor',
              headerName: 'Fornecedor',
              minWidth: 250,
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value.nome}`
            },

            {
              field: 'reqs',
              headerName: 'Requisições',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'transmissao',
              headerName: 'Transmissão',
              align: 'center',
              width: 120,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              renderCell: (params) => {
                if (params.value) {
                  return (
                    <Typography
                      sx={(theme) => ({
                        background: theme.palette.success.light,
                        color: theme.palette.success.contrastText,
                        py: theme.spacing(0.5),
                        px: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius
                      })}
                      fontSize={10}>
                      {new Date(params.value).toLocaleDateString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      sx={(theme) => ({
                        background: theme.palette.warning.light,
                        color: theme.palette.warning.contrastText,
                        py: theme.spacing(0.5),
                        px: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius
                      })}
                      fontSize={10}
                      color="error">
                      Não recebido
                    </Typography>
                  );
                }
              }
            },
            {
              field: 'movimento',
              headerName: 'Movimento',
              align: 'center',
              width: 90,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'obs',
              headerName: 'Observações',
              align: 'left',
              minWidth: 200,
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'transmitido',
              headerName: 'Status',
              align: 'center',
              width: 120,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              renderCell: (params) => {
                if (params.value) {
                  return (
                    <Box display={'flex'}>
                      <ThumbUpAltIcon
                        sx={(theme) => ({
                          color: theme.palette.success.main
                        })}
                      />
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.success.main,
                          py: theme.spacing(0.5),
                          px: theme.spacing(1),
                          borderRadius: theme.shape.borderRadius
                        })}
                        fontSize={10}>
                        Transmitido
                      </Typography>
                    </Box>
                  );
                } else {
                  return (
                    <Box display={'flex'}>
                      <ThumbDownAltIcon
                        sx={(theme) => ({
                          color: theme.palette.warning.main
                        })}
                      />
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.warning.main,
                          py: theme.spacing(0.5),
                          px: theme.spacing(1),
                          borderRadius: theme.shape.borderRadius
                        })}
                        fontSize={10}>
                        Pendente
                      </Typography>
                    </Box>
                  );
                }
              }
            }
          ]}
          // getRowClassName={(params) =>
          //   `super-app-theme--${!params.row.transmitido}`
          // }
          disableColumnFilter
          disableDensitySelector
          disableColumnMenu
          hideFooterSelectedRowCount
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSize={pageSize}
          rowCount={paramsQtdeRows}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginationMode="server"
          // onPageChange={handlePageChange}
          // checkboxSelection
          disableSelectionOnClick
          isRowSelectable={(params: GridRowParams) =>
            params.row.situacao.id === 3
          }
          onSelectionModelChange={(ids) => {
            if (!selectedRows) return;
            const selectedIDs = new Set(ids);
            if (!data) return;
            const selectedRowsData = data.filter((row) => {
              if (row.id) return selectedIDs.has(row.id);
            });

            selectedRows(selectedRowsData);
          }}
          // onRowDoubleClick={(params: GridRowParams) => {
          //   navigate(AppConfig.routes.cadPendenciaEdit(params.id as string));
          // }}
          localeText={{
            toolbarColumnsLabel: 'Colunas',
            toolbarColumns: '',
            toolbarExport: '',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Exportar para CSV',
            toolbarExportPrint: 'Imprimir',
            detailPanelToggle: 'Expandir',
            columnsPanelHideAllButton: 'Ocultar tudo',
            columnsPanelShowAllButton: 'Mostrar tudo',
            columnsPanelTextFieldLabel: 'Localizar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna'
          }}
          onRowDoubleClick={(params: GridRowParams) => {
            navigate(AppConfig.routes.cadPendenciaEdit(params.id as string));
          }}
        />
      </Box>
    </Paper>
  );
}
