/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RenderEditCellProps } from 'react-data-grid';
import { SelectColumn, textEditor } from 'react-data-grid';
import { TRow } from './rows';
import {
  numberEditor,
  numberEditor2,
  renderCurrency,
  renderNumber,
  renderTextCenter
} from '../../common/gridColumnsRendersEditors';
import { Switch } from '@mui/material';
import ChipPedido from '../../common/chipPedido';

function columns({ qtdeDigts = 2, precoDigts = 2 }): any[] {
  return [
    SelectColumn,
    {
      key: 'requisicaoId',
      name: 'ID',
      width: 35,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <center style={{ fontSize: '0.65em' }}>{props.column.name}</center>
      // ),
      renderCell: (props: any) => renderTextCenter(props)
    },
    {
      key: 'codigoSgi',
      name: 'Código SGI',
      width: 75,
      maxWidth: 100,
      minWidth: 75,
      headerCellClass: 'header-cell-size'
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <center style={{ fontSize: '0.65em' }}>{props.column.name}</center>
      // )
    },
    {
      key: 'descricao',
      name: 'Descrição',
      minWidth: 100,
      maxWidth: 900,
      width: 250,
      resizable: true,
      headerCellClass: 'header-cell-size',
      renderCell({ row }: { row: TRow }) {
        if (row?.pedido) {
          return (
            <div
              style={{
                fontWeight: 'bold',
                color: '#858585'
              }}>
              <ChipPedido pedidoId={row.pedido} /> <span>{row.descricao}</span>
            </div>
          );
        } else {
          return (
            <div
              style={{
                fontWeight: row.situacao.id !== 210 ? 'bold' : 'normal'
              }}>
              {row.descricao}
            </div>
          );
        }
      }
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // )
    },
    {
      key: 'situacao',
      name: 'Situação',
      maxWidth: 100,
      minWidth: 100,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderCell({ row }: { row: TRow }) {
        return (
          <div
            style={{
              backgroundColor: row.situacao.corFundo,
              color: row.situacao.corLetra,
              textAlign: 'center',
              fontWeight: row.situacao.id !== 210 ? 'bold' : 'normal'
            }}>
            {row?.situacao?.nome}
          </div>
        );
      }
    },
    {
      key: 'seuCodigo',
      name: 'Código Fornecedor',
      minWidth: 110,
      width: 110,
      maxWidth: 250,
      resizable: true,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderEditCell: (props: RenderEditCellProps<unknown, unknown>) =>
        textEditor(props)
    },
    {
      key: 'qtde',
      name: 'Qtde',
      minWidth: 90,
      maxWidth: 120,
      resizable: true,
      headerCellClass: 'header-cell-size',
      align: 'right',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      // renderEditCell: (props) => numberEditor(props),
      // editorOptions: {
      //   displayCellContent: false,
      //   commitOnOutsideClick: true
      // },
      // renderCell: (props: any) => renderNumber(props)
      renderCell: (props: any) => {
        const str_value = props.row[`${props.column.key}` as keyof TRow];
        const numDigits = 2;
        return parseFloat(str_value)?.toLocaleString('pt-Br', {
          minimumFractionDigits: numDigits > 2 ? numDigits : 0,
          maximumFractionDigits: numDigits
        });
      }
    },
    {
      key: 'preco',
      name: 'Preço(R$)',
      width: 80,
      minWidth: 60,
      maxWidth: 100,
      resizable: true,
      editable: (row: { pedido: any }) => !row.pedido,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderEditCell: (props: any) => numberEditor(props as any, precoDigts),
      editorOptions: {
        displayCellContent: false,
        commitOnOutsideClick: true
      },
      renderCell: (props: any) =>
        renderCurrency(props as any, '#f0f0f0', precoDigts)
    },
    {
      key: 'insdiponivel',
      name: 'Não tenho',
      maxWidth: 70,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderCell({
        row,
        onRowChange,
        tabIndex
      }: {
        row: TRow;
        onRowChange: any;
        tabIndex: number;
      }) {
        if (row.pedido) {
          return <></>;
        }
        if (!row.indisponivel) {
          return (
            <div style={{ padding: '0px' }}>
              <button
                style={{
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                tabIndex={tabIndex}
                onClick={() =>
                  onRowChange({
                    ...row,
                    indisponivel: true,
                    preco: 0,
                    situacao: {
                      id: 215
                    } as any
                  })
                }>
                <Switch />
              </button>
            </div>
          );
        } else {
          return (
            <button
              style={{
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              tabIndex={tabIndex}
              onClick={() =>
                onRowChange({
                  ...row,
                  indisponivel: false,
                  situacao: {
                    id: 210
                  } as any
                })
              }>
              <Switch defaultChecked />
            </button>
          );
        }
      }
    },
    {
      key: 'icms',
      name: 'ICMS(%)',
      width: 60,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      editable: (row: { pedido: any }) => !row.pedido,
      renderEditCell: (props: any) => numberEditor(props as any),
      editorOptions: {
        displayCellContent: false,
        commitOnOutsideClick: true
      },
      renderCell: (props: any) => renderNumber(props)
    },
    {
      key: 'ipi',
      name: 'IPI(%)',
      width: 60,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      editable: (row: { pedido: any }) => !row.pedido,
      renderEditCell: (props: any) => numberEditor(props as any),
      editorOptions: {
        displayCellContent: false,
        commitOnOutsideClick: true
      },
      renderCell: (props: any) => renderNumber(props)
    },
    {
      key: 'frete',
      name: 'Frete(%)',
      width: 60,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      editable: (row: { pedido: any }) => !row.pedido,
      renderEditCell: (props: any) => numberEditor(props as any),
      editorOptions: {
        displayCellContent: false,
        commitOnOutsideClick: true
      },
      renderCell: (props: any) => renderNumber(props)
    },
    {
      key: 'outrasDespesas',
      name: 'Outras(%)',
      width: 70,
      editable: (row: { pedido: any }) => !row.pedido,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderEditCell: (props: any) => numberEditor(props as any),
      editorOptions: {
        displayCellContent: false,
        commitOnOutsideClick: true
      },
      renderCell: (props: any) => renderNumber(props)
    },
    {
      key: 'precoComImposto',
      name: 'Preço + Impostos',
      width: 100,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderCell: (props: any) =>
        renderCurrency(props as any, '#ffd5b8', precoDigts)
    },
    {
      key: 'total',
      name: 'Total(R$)',
      width: 100,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      renderCell: (props: any) => renderCurrency(props as any, '#a3d6ff', 2)
    },
    {
      key: 'obs',
      name: 'Observações',
      resizable: true,
      minWidth: 100,
      headerCellClass: 'header-cell-size',
      // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
      //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
      // ),
      editable: (row: { pedido: any }) => !row.pedido,
      renderEditCell: (props: RenderEditCellProps<unknown, unknown>) =>
        textEditor(props)
    }
  ];
}

export default columns;
