import moment from 'moment';
import AppConfig from '../config/app';

export function parseLocalStorgeKey(key: string): string {
  return '@' + String(AppConfig.localStorageName).toLowerCase() + ':' + key;
}

export function toMySqlDate(date: string | Date): string {
  return new Date(date).toISOString().slice(0, 10).replace('T', ' ');
}

export function dateToMySql(date: Date): string {
  const endDay = moment(date).format('YYYY-MM-DD');
  return endDay;
}

export function toMySqlDateTime(date: string | Date): string {
  return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
}

export function fromMySqlDate(date: string | Date): string {
  if (typeof date === 'string') {
    return new Date(String(date).replace('-', '/')).toLocaleDateString(
      'pt-BR',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    );
  } else {
    return date.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }
}

export function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str));
}

export function UnicodeDecodeB64(str: string) {
  return decodeURIComponent(atob(str));
}

// conveter cor para hex com transparencia
export function hexToRgbA(hex: string, opacity: number) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = '0x' + c.join('');

    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

// função trunca texto com base no tamanho de caracteres
export function truncateText(text: string, size: number) {
  if (text.length > size) {
    return text.substring(0, size) + '...';
  } else {
    return text;
  }
}

// função para obter cores conforme porcentagem de 0 a 100  (vermelho, amarelo, verde)
export function getColorByPercentage(percentage: number) {
  if (percentage < 50) {
    return 'red';
  } else if (percentage >= 50 && percentage < 75) {
    return 'orange';
  } else {
    return 'green';
  }
}

export function getColorCircularProgress(percentage: number) {
  if (percentage < 50) {
    return 'error';
  } else if (percentage >= 50 && percentage < 75) {
    return 'warning';
  } else {
    return 'success';
  }
}

// parse string to number pt-br
export function isNumeric(str: string) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  );
}
