/* eslint-disable prettier/prettier */
import { Box, Breadcrumbs, Button, styled, Typography } from '@mui/material';

import { OUTLET_HEIGHT } from '../../../config/theme';

import TableRequisicao from '../tableRequisicao';
import { useGetSituacaosQuery } from '../../situacao/apiSlice';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import RightDrawer from '../../../components/RightDrawer';
import { FilterAltOutlined, NavigateNext } from '@mui/icons-material';

import { usePedido } from '../context';
import SelectFilter from '../../../components/filters/selectFilter';
import PeriodFilter from '../../../components/filters/periodFilter';
import { Pedido } from '../../pedido/models';
import AutocompleteFilter from '../../../components/filters/autocompleteFilter';
import { useGetFornecedoresQuery } from '../../fornecedor/apiSlice';
import SelectButtons from '../../../components/selectButtons';
import TablePedido from '../tablePedido';
import CustomSearch from '../../../components/CustomSearch';

const drawerWidth = 240;
const Inner = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: OUTLET_HEIGHT,
  flexGrow: 1,
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: 60,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

export default function PedidosList() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data,
    loading: isLoading,
    filterPedidos,
    filters,
    setFilters,
    setType,
    reload,
    searchFor,
    searchPedidos
  } = usePedido();

  const { data: dataFornecedores, isLoading: isLoadingFornecedores } =
    useGetFornecedoresQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  const [selectedRows, setSelectedRows] = useState<Pedido[]>([]);

  const [selectedView, setSelectedView] = useLocalStorage(
    'pendencia-selected-view',
    0
  );

  // filtro situacoes
  const { data: dataSituacoes, isLoading: isLoadingSituacoes } =
    useGetSituacaosQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlerFilter = useCallback(
    (filter: Record<string, any>) => {
      // verifica se a data inicial é maior que a data final
      const oldFilters = filters;
      const [dt1, dt2] = filter.created_at ?? [null, null];
      const dtIni = dt1 ? new Date(dt1) : null;
      const dtFim = dt2 ? new Date(dt2) : null;
      if (dtIni && dtFim && dtIni > dtFim) {
        enqueueSnackbar('A data inicial não pode ser maior que a data final', {
          variant: 'warning'
        });
        setFilters(oldFilters);
        return;
      }
      setType('filter');
      const newfilters = { ...filters, ...filter };
      setFilters(newfilters);
    },
    [enqueueSnackbar, filters, setFilters, setType]
  );

  // const clearFilters = async () => {
  //   const newFilters = {
  //     ...REQUISICAO_INITIAL_FILTERS
  //   };
  //   delete newFilters.searchFor;
  //   setFilters(newFilters);
  // };

  const [openDrawer, setOpenDrawer] = useLocalStorage(
    'pedido-right-drawer',
    false
  );

  async function handlerAprovar() {
    //
  }

  async function toFilter() {
    filterPedidos();
  }

  function mapDataToRowReqs() {
    if (!data) return [];
    const array = data
      .map((p: Pedido) =>
        p.reqs.map((r) => ({
          ...r,
          pedidoId: p.id,
          fornecedor: p.fornecedor
        }))
      )
      .flat();
    return array;
  }

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Inner open={openDrawer}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }} gap={1}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography fontWeight={'bold'}>Pendências</Typography>
        </Breadcrumbs>
        <Box display={'flex'} gap={2}>
          <Typography fontWeight={'bold'}>Visualizar por</Typography>
          <SelectButtons
            buttons={[
              { key: 0, label: 'Pedido' },
              { key: 1, label: 'Requisição' }
            ]}
            onSelect={setSelectedView}
            selected={selectedView}
          />
        </Box>
        <CustomSearch
          value={searchFor}
          handleSearch={(value) => {
            searchPedidos(value);
          }}
        />
      </Box>

      {selectedView === 0 ? (
        <TablePedido
          data={data ?? []}
          isLoading={isLoading}
          selectedRows={setSelectedRows}
        />
      ) : (
        <TableRequisicao
          data={mapDataToRowReqs() ?? []}
          isLoading={isLoading}
          // selectedRows={setSelectedRows}
          handlerAprovar={handlerAprovar}
        />
      )}

      <RightDrawer
        caption="Filtros"
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        setOpen={setOpenDrawer}>
        {/* filtros */}
        <Box
          display="flex"
          flexDirection={'column'}
          gap={1}
          sx={{ overflow: 'hidden', overflowY: 'auto' }}>
          {/* filtro de data */}
          <PeriodFilter
            dataKey="created_at"
            value={filters.created_at ?? [null, null]}
            onChange={handlerFilter}
            caption="Período"
          />
          {/* filtro de situações */}
          <SelectFilter
            dataKey="situacaoId"
            value={filters.situacaoId ?? []}
            loading={isLoadingSituacoes}
            data={
              dataSituacoes
                ? dataSituacoes
                    .filter(
                      (s) =>
                        s.id === 3 ||
                        s.id === 4 ||
                        s.id === 5 ||
                        s.id === 6 ||
                        s.id === 8 ||
                        s.id === 140
                    )
                    .map((el) => ({
                      id: el.id,
                      desc: el.nome.toUpperCase(),
                      corFundo: el.corFundo,
                      corLetra: el.corLetra
                    }))
                : []
            }
            onChange={handlerFilter}
            caption="Situações"
            // width={drawerWidth}
          />

          {/* filtro de fornecedores */}
          <AutocompleteFilter
            dataKey="fornecedorId"
            value={filters.fornecedorId ?? null}
            onChange={handlerFilter}
            caption="Fornecedor"
            loading={isLoadingFornecedores}
            data={
              dataFornecedores?.map((fornecedor) => ({
                id: fornecedor.id,
                desc: fornecedor.nome
              })) ?? []
            }
          />
        </Box>
        {/* ação de filtrar */}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50]
          })}
          py={1}
          display={'flex'}
          justifyContent={'space-between'}>
          <Button
            fullWidth
            onClick={toFilter}
            variant="contained"
            color="primary"
            size={'small'}>
            <FilterAltOutlined amplitude={8} /> Filtrar
          </Button>
        </Box>
      </RightDrawer>
    </Inner>
  );
}
