import { Paper, Box, IconButton, alpha, Typography } from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams
} from '@mui/x-data-grid';
// import { GridToolbar } from '@mui/x-data-grid/components';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Fornecedor } from '../fornecedor/models';
import { CustomNoTableRowsOverlay } from '../../components/NoCustomRowOverlay';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';
import { useEmpresa } from '../../hooks/useEmpresa';

interface Props {
  data: Record<string, any>[];
  isLoading: boolean;
  selectedRows?: (selectedRowsData: Record<string, any>[]) => void;
  handlerAprovar?: () => void;
}

export default function TableRequisicao({
  data,
  isLoading,
  selectedRows,
  handlerAprovar
}: Props) {
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useLocalStorage('table-pedido-pagesize', 10);

  function pageSizeChange(newPageSize: number) {
    setPageSize(newPageSize);
  }

  function mapDataToGridRows(data: Record<string, any>[]) {
    return data.map((d) => ({
      id: d.id,
      tipoReq: d.tipoReq,
      codigoSgi: d?.produtoServico?.codigoSgi,
      descricaoProdutoServico: d.descricaoProdutoServico,
      unidade: d.unidade,
      qtde:
        qtdeDigitosCampoQtde > 2
          ? Number(d.qtde).toLocaleString('pt-BR', {
              minimumFractionDigits: qtdeDigitosCampoQtde,
              maximumFractionDigits: qtdeDigitosCampoQtde
            })
          : Number(d.qtde).toLocaleString('pt-BR', {
              minimumFractionDigits: 0,
              maximumFractionDigits: qtdeDigitosCampoQtde
            }),
      previsao: d.previsao,
      fornecedor: d.fornecedor,
      pedidoId: d.pedidoId,
      obs: d.obs,
      situacao: d?.situacao || {}
    }));
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columnsRequisicaoAprovacaoVisibles',
      {
        // id: true,
        // tipoReq: true,
        // createdAt: true,
        // descricaoProdutoServico: true,
        // codigoSgi: true,
        // classe: true,
        // solicitante: true,
        // departamento: true,
        // unidade: true,
        // qtde: true,
        // situacao: true,
        // seuCodigo: true,
        // prioridade: true,
        // acoes: true
      }
    );

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        {/* <GridToolbarExport variant="text" /> */}
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  // function CustomPagination() {
  //   // const apiRef = useGridApiContext();
  //   // const rows = useGridSelector(apiRef, gridRowCountSelector);

  //   return (
  //     <Box
  //       minWidth={'96%'}
  //       display={'flex'}
  //       justifyContent={'space-between'}
  //       alignItems={'center'}
  //       gap={2}
  //       mx={2}
  //       px={0}>
  //       {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}
  //       {/* <Button
  //         onClick={handlerDesaprovar}
  //         variant="contained"
  //         color="primary"
  //         size="small"
  //         startIcon={<DoneAllIcon />}>
  //         Desaprovar
  //       </Button> */}

  //       {type === 'filter' ? (
  //         <Chip
  //           sx={{
  //             backgroundColor: 'primary.main',
  //             color: 'secondary.contrastText'
  //           }}
  //           icon={<GridFilterAltIcon />}
  //           label="Por Filtro"
  //           size="small"
  //         />
  //       ) : (
  //         <Chip
  //           sx={{
  //             backgroundColor: 'primary.main',
  //             color: 'secondary.contrastText'
  //           }}
  //           icon={<GridSearchIcon />}
  //           label="Por Texto"
  //           size="small"
  //         />
  //       )}
  //       <Box display={'flex'} alignItems={'center'} gap={1}>
  //         <Typography fontSize={12}>Qtde/página:</Typography>
  //         <RadioGroup
  //           sx={{ display: 'flex', flexDirection: 'row' }}
  //           name="controlled-radio-buttons-group"
  //           value={pageSize.toString()}
  //           onChange={(e) => pageSizeChange(Number(e.target.value))}>
  //           <FormControlLabel
  //             value="10"
  //             control={
  //               <Radio
  //                 sx={{
  //                   '& .MuiSvgIcon-root': {
  //                     fontSize: 18
  //                   }
  //                 }}
  //               />
  //             }
  //             label="10"
  //             sx={{
  //               '& .MuiFormControlLabel-label': {
  //                 fontSize: 12
  //               }
  //             }}
  //           />
  //           <FormControlLabel
  //             value="25"
  //             control={
  //               <Radio
  //                 sx={{
  //                   '& .MuiSvgIcon-root': {
  //                     fontSize: 18
  //                   }
  //                 }}
  //               />
  //             }
  //             label="25"
  //             sx={{
  //               '& .MuiFormControlLabel-label': {
  //                 fontSize: 12
  //               }
  //             }}
  //           />
  //           <FormControlLabel
  //             value="50"
  //             control={
  //               <Radio
  //                 sx={{
  //                   '& .MuiSvgIcon-root': {
  //                     fontSize: 18
  //                   }
  //                 }}
  //               />
  //             }
  //             label="50"
  //             sx={{
  //               '& .MuiFormControlLabel-label': {
  //                 fontSize: 12
  //               }
  //             }}
  //           />
  //         </RadioGroup>
  //       </Box>
  //       <Box display={'flex'} alignItems={'center'} gap={1}>
  //         <Pagination
  //           sx={{
  //             '.MuiPaginationItem-rounded': {
  //               color: '#fff'
  //             }
  //           }}
  //           shape="rounded"
  //           color="primary"
  //           count={paramsQtde}
  //           page={filterParams.skip + 1}
  //           onChange={(event, value) =>
  //             setFilterParams({
  //               ...filterParams,
  //               skip: value - 1,
  //               take: pageSize
  //             })
  //           }
  //           showFirstButton
  //           showLastButton
  //         />
  //         <Typography fontSize={12}>Qtde: {paramsQtdeRows}</Typography>
  //       </Box>
  //     </Box>
  //   );
  // }

  const mapToExcel = data.map((d) => ({
    Req: d.id,
    'Código SGI': d?.produtoServico?.codigoSgi,
    Descrição: d.descricaoProdutoServico,
    'Código Fornecedor': d.seuCodigo,
    Und: d.unidade,
    Qtde: d.qtde,
    Pedido: d.pedidoId,
    Fornecedor: d.fornecedor?.nome
  }));

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'CotacaoRequisicoes.xlsx');
  }

  return (
    <Paper>
      <Box height="80vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 14,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& .super-app-theme--true': {
              bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.success.main
              }
            },
            '& .super-app-theme--false': {
              bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.warning.main
              }
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          loading={isLoading}
          paginationMode="server"
          rows={data ? mapDataToGridRows(data) : []}
          density="compact"
          columns={[
            {
              field: '__check__',
              hideable: false,
              sortable: false,
              width: 50,
              disableColumnMenu: true,
              disableExport: true,
              headerName: 'Selecionar'
            },
            {
              field: 'id',
              headerName: 'ID',
              align: 'center',
              width: 60,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'codigoSgi',
              headerName: 'Código SGI',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'descricaoProdutoServico',
              headerName: 'Descrição',
              minWidth: 250,
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'seuCodigo',
              headerName: 'Código Fornecedor',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'unidade',
              headerName: 'Und',
              align: 'center',
              width: 80,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'qtde',
              headerName: 'Qtde',
              width: 90,
              align: 'right',
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'pedidoId',
              headerName: 'Pedido',
              width: 80,
              align: 'center',
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'fornecedor',
              headerName: 'Fornecedor',
              minWidth: 250,
              flex: 1,
              align: 'left',
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: (params: GridValueFormatterParams) => {
                const fornecedor = params.value as Fornecedor;
                return fornecedor ? fornecedor.nome : '';
              }
            },
            {
              field: 'situacao',
              headerName: 'Situação',
              align: 'center',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value?.nome || ''}`,
              renderCell: (params: any) => {
                return (
                  <Typography
                    fontSize={10}
                    sx={(theme) => ({
                      color: params.row.situacao?.corLetra || '#000000',
                      background: params.row.situacao?.corFundo,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.5),
                      borderRadius: theme.shape.borderRadius
                    })}>
                    {params.row.situacao?.nome}
                  </Typography>
                );
              }
            }
          ]}
          disableColumnFilter
          disableDensitySelector
          disableColumnMenu
          components={{
            Toolbar: CustomToolbar,
            // LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              showColumnsButton: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          pageSize={pageSize}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          // rowsPerPageOptions={[10, 25, 50, 100]}
          // paginationMode="server"
          // onPageChange={handlePageChange}
          checkboxSelection
          disableSelectionOnClick
          // isRowSelectable={(params: GridRowParams) =>
          //   params.row.situacao.id === 3
          // }
          onSelectionModelChange={(ids) => {
            if (!selectedRows) return;
            const selectedIDs = new Set(ids);
            if (!data) return;
            const selectedRowsData = data.filter((row) => {
              if (row.id) return selectedIDs.has(row.id);
            });

            selectedRows(selectedRowsData);
          }}
          // onRowDoubleClick={(params: GridRowParams) => {
          //   navigate(AppConfig.routes.cadPendenciaEdit(params.id as string));
          // }}
          hideFooter
          localeText={{
            toolbarColumnsLabel: 'Colunas',
            toolbarColumns: '',
            toolbarExport: '',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Exportar para CSV',
            toolbarExportPrint: 'Imprimir',
            detailPanelToggle: 'Expandir',
            columnsPanelHideAllButton: 'Ocultar tudo',
            columnsPanelShowAllButton: 'Mostrar tudo',
            columnsPanelTextFieldLabel: 'Localizar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna'
          }}
        />
      </Box>
    </Paper>
  );
}
